import * as React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../../components/Layout/Layout"
import Seo from "../../components/Layout/Seo"

import { HeroSimple } from '../../components/Components/Heroes/HeroSimple';
import Navbar from '../../components/Layout/Navbar';
import { LandingGrid } from '../../components/Components/Content/LandingGrid';
import { PageTitle } from "../../components/Components/Headings/PageTitle"
import { MainGrid } from '../../components/Components/Content/MainGrid';
import { ColumnGrid } from '../../components/Components/Content/ColumnGrid';



const LibraryMusic = ({ data }) => {

  console.log(data);

  //const image = getImage(data.image.childImageSharp.gatsbyImageData)

  const projects = data.projects.nodes;

  return (
    <Layout>
      <Seo
        title="Kinzica Studio | Library Music"
        description="Kinzica Studio library music offers a large variety of works"
        url="www.kinzicastudio.com/works/library-music"
        keywords={["Kinzica Studio Library music", "Kinzica Studio Library music works", "Kinzica studio"]}
      />
      <div className="sm:w-11/12 w-full mx-auto">
        <PageTitle id="library-music" title="Library Music" subtitle="Check our works!" />
        <MainGrid id={"all-library-music"} projects={projects} />
      </div>
    </Layout >
  )
}

export default LibraryMusic;


export const query = graphql`
query LibraryMusicWorks {
  projects: allMarkdownRemark(filter: {frontmatter: {tag: {eq: "library-music"}}}) {
    nodes {
      frontmatter {
        slug
        tag
        title
        thumb {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
      id
    }
  }
}
`;
